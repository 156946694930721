import { usePathname } from 'next/navigation';

import { Button } from 'site-react/components/form';
import { OutboundLink, LinkAsButton } from 'site-react/components/navigation';
import { Grid, UICard } from 'site-react/components/page';
import { LazyDrawer } from 'site-react/components/page/Drawer';
import { Heading, Paragraph } from 'site-react/components/typography';
import { ImgixImage, VerticalSpacing } from 'site-react/components/utility';
import config from 'site-react/config';
import advisors from 'site-react/data/advisors';
import getAppUrl from 'site-react/helpers/getAppUrl';
import useFeatureFlags from 'site-react/hooks/useFeatureFlags';

import styles from './HelpWithSearch.module.css';

export default function HelpWithSearch() {
  const { enableOnSiteGetAQuoteForm } = useFeatureFlags();

  const pathname = usePathname();
  const appUrl = getAppUrl(config);

  const urlSource = new URL(pathname, appUrl);
  const getAQuoteUrl = new URL(
    `https://search.hubblehq.com/get-a-quote?utm_source=navigation&utm_medium=platform&url_source=${urlSource.toString()}`,
  );

  return (
    <div className={styles.HelpWithSearch}>
      <UICard
        backgroundColor="brandsecondary-background"
        boxShadow="resting"
        name="Get help with your office search"
        style={{ border: 'none', padding: 'var(--space-md)' }}
      >
        <VerticalSpacing size="xl" />
        <div className={styles['HelpWithSearch-content']}>
          <Grid>
            <Grid.Item colSpan="12" justify="center">
              <Heading isCentered level="1" type="title4">
                Get expert help finding your next office
              </Heading>

              <VerticalSpacing size="sm" />

              <Paragraph isCentered type="content3">
                We’ll recommend the best offices for your needs, arrange your
                viewings and negotiate discounts.
              </Paragraph>
            </Grid.Item>
            <Grid.Item colSpan="12" justify="center">
              {enableOnSiteGetAQuoteForm ? (
                <LazyDrawer
                  label="General enquiry"
                  lazyComponent={async () =>
                    (await import('site-react/features/GeneralEnquiry')).default
                  }
                  renderTrigger={({ isLoading, openDrawer }) => (
                    <Button
                      disabled={isLoading}
                      isLoading={isLoading}
                      label="Get a quote"
                      name="Send us your brief"
                      onClick={openDrawer}
                      type="button"
                    >
                      Send us your brief
                    </Button>
                  )}
                >
                  {(GeneralEnquiry) => (
                    <div
                      className={styles['HelpWithSearch-generalEnquiryWrapper']}
                    >
                      <GeneralEnquiry />
                    </div>
                  )}
                </LazyDrawer>
              ) : (
                <LinkAsButton
                  href={getAQuoteUrl.toString()}
                  name="Send us your brief"
                >
                  Send us your brief
                </LinkAsButton>
              )}
            </Grid.Item>
            <Grid.Item align="center" colSpan="12">
              <OutboundLink
                href="https://www.trustpilot.com/review/hubblehq.com"
                styleType="secondary"
                target="_blank"
              >
                <div className={styles['HelpWithSearch-trustpilot']}>
                  <ImgixImage
                    alt="Trustpilot five star score"
                    height={20}
                    src="/site/global/trustpilot-stars-1.svg"
                    width={80}
                  />
                  <span>
                    <q className={styles['HelpWithSeach-quote']}>
                      Saved a lot of time
                    </q>{' '}
                    -{' '}
                    <cite className={styles['HelpWithSearch-author']}>
                      Andy
                    </cite>
                  </span>
                </div>
              </OutboundLink>
            </Grid.Item>
          </Grid>
        </div>
      </UICard>

      <div className={styles['HelpWithSearch-advisors']}>
        <ImgixImage
          alt="Advisor image"
          height={52}
          src={advisors[53195].image}
          width={52}
        />
        <ImgixImage
          alt="Advisor image"
          height={64}
          src={advisors[96669].image}
          width={64}
        />
        <ImgixImage
          alt="Advisor image"
          height={52}
          src={advisors[100493].image}
          width={52}
        />
      </div>
    </div>
  );
}
