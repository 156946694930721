import { yupResolver } from '@hookform/resolvers/yup';
import { useRouter } from 'next/navigation';
import PropTypes from 'prop-types';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { Button, Radio, SelectorPills } from 'site-react/components/form';
import { Grid } from 'site-react/components/page';
import { Heading, TextWithIcon } from 'site-react/components/typography';
import { VerticalSpacing } from 'site-react/components/utility';
import config from 'site-react/config';
import LocationAutocomplete from 'site-react/features/LocationAutocomplete';
import getAppUrl from 'site-react/helpers/getAppUrl';

import styles from './SearchHeroForm.module.css';

const OfficeOptionValues = {
  FullTime: 'full-time',
  PartTime: 'part-time',
};

const SearchHeroForm = ({ workspaceOfferings }) => {
  const [isLocationAutocompleteOpen, setIsLocationAutocompleteOpen] =
    React.useState(() => false);

  const validationSchema = Yup.object({
    anchorSlug: Yup.string().test(
      'is-autocomplete-closed',
      'Please select a location',
      () => !isLocationAutocompleteOpen,
    ),
    areaSlug: Yup.string(),
    officeOption: Yup.string().oneOf([
      OfficeOptionValues.FullTime,
      OfficeOptionValues.PartTime,
    ]),
    productOption: Yup.string().oneOf(Object.keys(workspaceOfferings)),
  });

  const router = useRouter();

  const {
    clearErrors,
    formState,
    handleSubmit,
    register,
    setValue,
    trigger,
    watch,
  } = useForm({
    defaultValues: {
      anchorSlug: '',
      areaSlug: '',
      officeOption: 'full-time',
      productOption: 'hq',
    },
    resolver: yupResolver(validationSchema),
  });

  const buildRedirectUrl = (product, anchorSlug, areaSlug) => {
    let redirectUrl;
    if (product === 'part-time' || product === 'full-time') {
      redirectUrl = new URL(`/${product}-office-enquiry`, getAppUrl(config));
      if (anchorSlug) {
        redirectUrl.searchParams.set('anchorSlug', anchorSlug);
      }
      if (areaSlug) {
        redirectUrl.searchParams.set('areaSlug', areaSlug);
      }
    } else {
      if (anchorSlug || areaSlug) {
        redirectUrl = new URL(
          `/${product}${anchorSlug ? `/${anchorSlug}` : ''}${areaSlug && areaSlug !== anchorSlug ? `/${areaSlug}` : ''}`,
          getAppUrl(config),
        );
      } else {
        redirectUrl = new URL(
          `/pass/go?productType=${product}`,
          getAppUrl(config),
        );
      }
    }

    redirectUrl.searchParams.set('source', 'homepage');
    return redirectUrl.toString();
  };

  const onSubmit = (data) => {
    const product =
      data.productOption === 'hq' ? data.officeOption : data.productOption;
    const redirectUrl = buildRedirectUrl(
      product,
      data.anchorSlug,
      data.areaSlug,
    );

    router.push(redirectUrl);
  };

  return (
    <form
      method="post"
      name="full-time-enquiry"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={styles['SearchHeroForm-heading']}>
        <Heading color="neutral-900" type="title4">
          Search the UK’s widest selection of:
        </Heading>
      </div>

      <VerticalSpacing size="sm" />

      <SelectorPills.List horizontalScroll>
        {Object.entries(workspaceOfferings).map(([key, value]) => (
          <SelectorPills.Item
            id={`product-${key}`}
            key={key}
            name={value}
            style={{
              border: '1px solid var(--color-neutral-900)',
              borderRadius: 'var(--space-xl)',
              padding: `var(--space-lg)`,
            }}
            type="radio"
            value={key}
            {...register('productOption', {
              onChange: (event) => {
                trigger('productOption');
              },
            })}
          >
            <span className={styles['SearchHeroForm-productTitle']}>
              {value}
            </span>
          </SelectorPills.Item>
        ))}
      </SelectorPills.List>

      <VerticalSpacing size="md" />

      {watch('productOption') === 'hq' ? (
        <fieldset
          className={styles['SearchHeroForm-officeOptionContainer']}
          name="officeOption"
        >
          <Radio
            className={styles['SearchHeroForm-officeOptionContainer-radio']}
            id="Full time office option"
            label={
              <>
                <span
                  className={
                    styles['SearchHeroForm-officeOptionContainer-radioLabel']
                  }
                  style={{ fontWeight: `var(--font-weight-medium)` }}
                >
                  Full time &nbsp;
                </span>
                <span
                  className={
                    styles['SearchHeroForm-officeOptionContainer-radioLabel']
                  }
                >
                  7 days / week
                </span>
              </>
            }
            value={OfficeOptionValues.FullTime}
            {...register('officeOption', {
              onChange: () => trigger('officeOption'),
            })}
          />
          <Radio
            className={styles['SearchHeroForm-officeOptionContainer-radio']}
            id="Part Time office option"
            label={
              <>
                <span
                  className={
                    styles['SearchHeroForm-officeOptionContainer-radioLabel']
                  }
                  style={{ fontWeight: `var(--font-weight-medium)` }}
                >
                  Part time &nbsp;
                </span>
                <span
                  className={
                    styles['SearchHeroForm-officeOptionContainer-radioLabel']
                  }
                >
                  1-3 days / week
                </span>
              </>
            }
            value={OfficeOptionValues.PartTime}
            {...register('officeOption', {
              onChange: () => trigger('officeOption'),
            })}
          />
        </fieldset>
      ) : null}
      <VerticalSpacing size="sm" />
      <Grid gridGapY="sm">
        <Grid.Item colSpan="9">
          <div className={styles['SearchHeroForm-locationAutocomplete']}>
            <LocationAutocomplete
              boxShadow="subtle"
              clearErrors={() => {
                clearErrors('anchorSlug');
                clearErrors('areaSlug');
              }}
              errorText={formState.errors.anchorSlug?.message}
              onChangeCallback={() => {
                clearErrors('anchorSlug');
                clearErrors('areaSlug');
                setValue('anchorSlug', '');
                setValue('areaSlug', '');
              }}
              placeholder="Start typing a city, area or postcode"
              setIsAutocompleteOpen={setIsLocationAutocompleteOpen}
              setValue={(...args) => {
                clearErrors('anchorSlug');
                clearErrors('areaSlug');
                setValue(...args);
              }}
              status={formState.errors.anchorSlug?.message && 'error'}
            />
          </div>
        </Grid.Item>
        <Grid.Item
          align={formState.errors.anchorSlug ? 'center' : 'end'}
          colSpan="3"
        >
          <Button isBlock name="Search" type="submit">
            <TextWithIcon
              contentType="content2"
              iconName="search"
              iconPosition="left"
              iconSize="md"
              text="Search"
            />
          </Button>
        </Grid.Item>
      </Grid>
    </form>
  );
};

SearchHeroForm.propTypes = {
  /**
   * Object containing the workspace offerings.
   */
  workspaceOfferings: PropTypes.object.isRequired,
};

export default SearchHeroForm;
