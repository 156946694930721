import cn from 'classnames';

import { UnstyledButton } from 'site-react/components/form';
import { Link } from 'site-react/components/navigation';
import { Grid, UICard } from 'site-react/components/page';
import { LazyDrawer } from 'site-react/components/page/Drawer';
import {
  Heading,
  Paragraph,
  TextWithIcon,
} from 'site-react/components/typography';
import { ImgixImage, Spinner } from 'site-react/components/utility';
import useFeatureFlags from 'site-react/hooks/useFeatureFlags';

import styles from './HelpWithSearch.module.css';

function GeneralEnquiryWrapper({ children, enableOnSiteGetAQuoteForm }) {
  if (enableOnSiteGetAQuoteForm) {
    return (
      <LazyDrawer
        label="General enquiry"
        lazyComponent={async () =>
          (await import('site-react/features/GeneralEnquiry')).default
        }
        renderTrigger={({ isLoading, openDrawer }) => (
          <UnstyledButton
            className={cn(styles['HelpWithSearch-generalEnquiryButton'], {
              [styles['HelpWithSearch-generalEnquiryButton--loading']]:
                isLoading,
            })}
            isEnabled={!isLoading}
            label="General enquiry"
            onClick={openDrawer}
            type="button"
          >
            {isLoading ? <Spinner size="small" /> : children}
          </UnstyledButton>
        )}
      >
        {(GeneralEnquiry) => (
          <div className={styles['HelpWithSearch-generalEnquiryWrapper']}>
            <GeneralEnquiry />
          </div>
        )}
      </LazyDrawer>
    );
  } else {
    return (
      <Link href="https://hubbleomu.typeform.com/to/fHV0eYlX?utm_source=source=homepageheromay24&utm_medium=website&utm_campaign=getintouch">
        {children}
      </Link>
    );
  }
}

export default function HelpWithSearch() {
  const { enableOnSiteGetAQuoteForm } = useFeatureFlags();

  return (
    <UICard
      backgroundColor="brandsecondary-light"
      boxShadow="resting"
      name="Get help with your office search"
      style={{ border: 'none' }}
    >
      <Grid gridGapY="sm">
        <Grid.Item colSpan="1">
          <div className={styles['HelpWithSearch-wrapper']}>
            <div className={styles['HelpWithSearch-advisoryImageDesktop']}>
              <ImgixImage
                alt="The hubble advisors"
                height={64}
                src="/site/team/blob-style/advisory-group.png"
                width={79}
              />
            </div>
            <div className={styles['HelpWithSearch-advisoryImageMobile']}>
              <ImgixImage
                alt="The hubble advisors"
                height={33}
                src="/site/team/blob-style/advisory-group-landscape.png"
                width={86}
              />
            </div>
          </div>
        </Grid.Item>
        <Grid.Item colSpan="8">
          <div className={styles['HelpWithSearch-wrapper']}>
            <Heading level="1" type="title4">
              Get free, friendly help with your office search
            </Heading>
            <Paragraph type="content3">
              Zero&#8209;pressure advice, recommendations, and help with
              negotiations, at no extra cost.
            </Paragraph>
          </div>
        </Grid.Item>
        <Grid.Item colSpan="3">
          <div
            className={`${styles['HelpWithSearch-wrapper']} ${styles['HelpWithSearch-ctaWrapper']}`}
          >
            <GeneralEnquiryWrapper
              enableOnSiteGetAQuoteForm={enableOnSiteGetAQuoteForm}
            >
              <b>
                <TextWithIcon
                  contentType="content1"
                  iconName="chevron_right"
                  iconPosition="right"
                  iconSize="lg"
                  text="Get in touch"
                />
              </b>
            </GeneralEnquiryWrapper>
            <ImgixImage
              alt="Trustpilot five star score"
              height={14}
              src="/site/homepage/trustpilot-black-letters.svg"
              width={180}
            />
          </div>
        </Grid.Item>
      </Grid>
    </UICard>
  );
}
