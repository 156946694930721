import { OutboundLink } from 'site-react/components/navigation';
import { Grid, UICard } from 'site-react/components/page';
import { Heading } from 'site-react/components/typography';
import { ImgixImage, VerticalSpacing } from 'site-react/components/utility';

import HelpWithSearch from './HelpWithSearch';
import styles from './SearchHero.module.css';
import SearchHeroForm from './SearchHeroForm';
import UspCards from './UspCards';

// The order of these keys is important for the order of the radio buttons
/* eslint-disable sort-keys */
const workspaceOfferings = {
  hq: 'Offices',
  'meeting-rooms': 'Meeting rooms',
  coworking: 'Coworking desks',
  'private-day-offices': 'Day offices',
};
/* eslint-enable sort-keys */

export default function SearchHero() {
  return (
    <div data-testid="SearchHero">
      <Grid breakpoint="lg" gridGapY="md">
        <Grid.Item colSpan="7">
          <div className={styles['SearchHero-content']}>
            <Heading level="1" type={{ default: 'title3', md: 'hero2' }}>
              We take the hassle out of finding the perfect workspace
            </Heading>

            <VerticalSpacing size="sm" />

            <UspCards />

            <VerticalSpacing size="sm" />

            <OutboundLink
              href="https://www.trustpilot.com/review/hubblehq.com"
              target="_blank"
            >
              <ImgixImage
                alt="Trustpilot five star score"
                height={14}
                src="/site/homepage/trustpilot-black-letters.svg"
                width={180}
              />
            </OutboundLink>
          </div>
        </Grid.Item>
        <Grid.Item colSpan="7">
          <UICard
            boxShadow="resting"
            style={{
              padding: {
                default: `var(--space-sm)`,
                lg: `var(--space-lg)`,
              },
            }}
          >
            <SearchHeroForm workspaceOfferings={workspaceOfferings} />
          </UICard>
        </Grid.Item>
        <Grid.Item colSpan="5" colStart="8" rowSpan="3" rowStart="1">
          <figure className={styles['SearchHero-image']}>
            <ImgixImage
              alt="Runway East - Soho - Wardour Street"
              height={1133}
              layout="responsive"
              src="/homepage/hero-images/runway-east.png"
              style={{
                borderRadius: 'var(--space-md)',
                objectFit: 'contain',
              }}
              width={1789}
            />
            <figcaption className={styles['SearchHero-imageCaption']}>
              <b>Runway East - Soho - Wardour Street</b>
            </figcaption>
          </figure>
        </Grid.Item>

        <Grid.Item colSpan="5" colStart="8" justify="center" rowStart="2">
          <div className={styles['SearchHero-advisorBanner']}>
            <HelpWithSearch />
          </div>
        </Grid.Item>
      </Grid>
    </div>
  );
}
