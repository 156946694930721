import React from 'react';

import { TextWithIcon } from 'site-react/components/typography';

import styles from './UspCards.module.css';

const UspCards = () => {
  const uspContent = [
    '100% office market coverage',
    'Real listings',
    'Transparent pricing',
  ];

  return (
    <div className={styles.UspCards}>
      {uspContent.map((content) => (
        <TextWithIcon
          contentType="content1"
          iconName="star"
          iconPosition="left"
          iconSize="md"
          key={content}
          text={content}
        />
      ))}
    </div>
  );
};

export default UspCards;
