import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { LazyDrawer } from 'site-react/components/page/Drawer';
import Home from 'site-react/features/Home';
import Analytics from 'site-react/helpers/Analytics';
import getABCohort from 'site-react/helpers/getABCohort';
import useABTest from 'site-react/hooks/useABTest';
import useCookiePreferences from 'site-react/hooks/useCookiePreference';

import styles from './index.module.css';
import meta from '../content/meta/home';
import PageLayout from '../layouts/PageLayout';

const HBL_EXPERIMENT_HOMEPAGE_HERO = 'hbl_experiment_homepage_hero';

const HomePage = ({ cohort, forceOpenGeneralEnquiry = false, utmParams }) => {
  const { hasSetPreferences } = useCookiePreferences();

  const [hasGeneralEnquiryBeenClosed, setHasGeneralEnquiryBeenClosed] =
    useState(false);

  useABTest(cohort, 'homepage_hero', HBL_EXPERIMENT_HOMEPAGE_HERO);

  useEffect(() => {
    Analytics.setPageProperties({
      page_category: 'static',
      page_type: 'home',
    });

    Analytics.pageTrack('Home');
  }, []);

  return (
    <PageLayout meta={meta}>
      <Home cohort={cohort} utmParams={utmParams} />

      {hasSetPreferences &&
      forceOpenGeneralEnquiry &&
      !hasGeneralEnquiryBeenClosed ? (
        <LazyDrawer
          isOpenOnRender
          label="General enquiry"
          lazyComponent={async () =>
            (await import('site-react/features/GeneralEnquiry')).default
          }
          onCloseCallback={() => {
            setHasGeneralEnquiryBeenClosed(true);
          }}
          renderTrigger={({ openDrawer }) => {
            openDrawer();
          }}
        >
          {(GeneralEnquiry) => (
            <div className={styles['Home-generalEnquiryWrapper']}>
              <GeneralEnquiry />
            </div>
          )}
        </LazyDrawer>
      ) : null}
    </PageLayout>
  );
};

export const getServerSideProps = async ({ req, query }) => {
  const cohort = await getABCohort(req, HBL_EXPERIMENT_HOMEPAGE_HERO);

  const utmParams = new URLSearchParams();

  for (const [key, value] of Object.entries(query)) {
    if (key.startsWith('utm_')) {
      utmParams.set(key, encodeURIComponent(value));
    }
  }

  return {
    props: {
      cohort: query?.cohort || cohort,
      forceOpenGeneralEnquiry: query?.forceOpenGeneralEnquiry === 'true',
      utmParams: utmParams.toString(),
    },
  };
};

HomePage.propTypes = {
  cohort: PropTypes.oneOf(['control', 'variant']).isRequired,

  utmParams: PropTypes.string,
};

export default HomePage;
