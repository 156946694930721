import Cookies from 'js-cookie';
import { useEffect } from 'react';

import cookieTypes from 'site-react/data/core/CookiePreferencesContext/cookieTypes';
import getDataLayer from 'site-react/helpers/dataLayer';
import useCookiePreferences from 'site-react/hooks/useCookiePreference';

/**
 * Hook to track an A/B test.
 *
 * @param {string} cohort - One of 'control' or 'variant'.
 * @param {string} experimentName - The name of the experiment. Should be snake_case.
 * @param {string} cookieName - The name of the cookie to store the cohort value in. Should be snake_case.
 */
export default function useABTest(cohort, experimentName, cookieName) {
  const { hasSetPreferences, preferences } = useCookiePreferences();

  useEffect(() => {
    getDataLayer().push({
      event: 'experiment_view',
      experiment_cohort: cohort,
      experiment_name: experimentName,
    });
  }, [cohort, experimentName]);

  useEffect(() => {
    if (!hasSetPreferences) {
      return;
    }

    const enableExperimentCookie = preferences.some(
      (preference) => preference.name === cookieTypes.marketingAndAnalytics,
    );

    if (enableExperimentCookie) {
      Cookies.set(cookieName, cohort, {
        expires: 365,
      });
    } else {
      Cookies.remove(cookieName);
    }
  }, [hasSetPreferences, preferences, cohort, cookieName]);
}
